export const fundingSources = [
  'National Science Foundation',
  'Graduate Research Fellowship',
  'Colorado State University',
  'Vice President for Research (VPR) Graduate Fellows Program',
  'Graduate Degree Program in Ecology Training & Travel Grant',
  'Graduate Student Council (GSC) Professional Development & Supply Grant',
  'The Thomas A. Jones Graduate Fellowship',
  'The Douglas L. Gilbert Memorial Scholarship',
  'Wilson Ornithological Society Student Research Grant',
  'Sea and Sage Audubon Society Bloom-Hays Ecological Research Grant',
  'Pasadena Audubon Society Student Research Grant',
  'The Garden Club of America Centennial Pollinator Fellowship',
  'The American Philosophical Society Lewis and Clark Fund for Exploration and Field Research',
  'American Ornithological Society Student Research Grant',
];
